import { createStore,applyMiddleware } from "redux";
import AllReducers from './reducers/AllReducers'
import promise from 'redux-promise'
import multi from 'redux-multi'
import thunk from 'redux-thunk'


//Função que pega a data atual como default ;

    var data = new Date();
    var dia = data.getDate();
    var mes = data.getMonth();
    var ano = data.getFullYear();

    if(mes + 1 < 10){
        var compMes = 0
    }if(dia<10){
        var compDia = 0;
    }if(dia>=10){
        compDia = '';
    }            
    var str_date =ano + '-' + compMes+(mes+1) + '-' + compDia+(dia) 
    



const initialStates = {
    auth: {
        loggedIn: false,
        user:{},
        
        
    },
    pilula:{
        nivelPilula:[],
        categoriaPilula:[],
        pilulas:[],
        asc:true,
        desc:false,
        order:'desc',
        th:'pilula.dta_criacao',
        qtd_per_page:10,
        action:'lista'
        
        
       
    },
    agenda_pilula:{
        nivelPilula:[],
        categoriaPilula:[],
        agendas_pilulas:[],
        asc:false,
        desc:true,
        order:'desc',
        th:'agenda_pilula.data_agenda',
        qtd_per_page:10,    
    },
    relatorio:{
        data_atual:str_date,
        asc:false,
        desc:true,
        order:'desc',
        th:'data_resposta',
        qtd_per_page:10,
        relatorios:[]
    },
    user:{
        asc:true,
        desc:false,
        order:'asc',
        th:'usuario.nome',
        qtd_per_page:10,
        users:[]

    },
    categoria:{
        asc:true,
        desc:false,
        order:'asc',
        th:'categoria.nome',
        qtd_per_page:10,
        categorias:[]
    },
    cooperativa:{
        asc:true,
        desc:false,
        order:'asc',
        th:'cooperativa.des_cooperativa',
        qtd_per_page:10,
        cooperativas:[]
    },
    setor:{
        asc:true,
        desc:false,
        order:'asc',
        th:'setor.des_setor',
        qtd_per_page:10,
        setores:[]
    }
}
const store = applyMiddleware(thunk,multi,promise)(createStore)(AllReducers,initialStates,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )

export default store;
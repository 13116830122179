import React, { Component } from 'react'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Footer from '../../Footer/footer'
import Table from '../../Table/table'
import Thead from '../../Table/thead'
import Tbody from '../../Table/tbody'
import Tfoot from '../../Table/tfoot'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, order, change_qtd_per_page, changePesquisa, clearPesquisa, handleCheeseChange } from '../Cooperativas/Actions/actions'
import { pagination } from '../../Pagination/paginations'
import { Link } from 'react-router-dom'
import Pagination from '../../Table/pagination'
import Cabecalho from '../../Table/cabecalho'
import StylePage from '../../Style Page/style_page'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import ReactLoading from 'react-loading'
import { Content } from '../Users/styleLoading'
import Alert from '../../Alerts/alert'
import ModalExcluir from '../../Modal/modal'
import { delete_user } from '../../Services/user'

class grid_cooperativas extends Component {
    state = {
        loading: false,
        modal: false,
        id: '',
        success: '',
        error: ''

    }


    componentDidMount() {
        this.props.get(1)
    }

    onChangeModal = (id) => {
        this.setState({ modal: true, id: id })
    }

    handleCloseExcluir = event => {
        this.setState({ modal: false, id: '' })
    }

    //Função que mapeia o objeto com as pilulas
    renderGrid() {
        const cooperativas = this.props.cooperativas || []
        return cooperativas.map(desc => (
            <tr key={desc.cooperativa_id}>
                <td>{desc.nome_cooperativa}</td>
                <td>{desc.nome_empresa}</td>
                <td className="text-center">
                    <Toggle

                        id={desc.cooperativa_id}
                        checked={desc.ativo === 1 ? (true) : (false)}
                        onChange={this.props.handleCheeseChange} />
                    {/* <label htmlFor='cheese-status'>Adjacent label tag</label> */}

                </td>
                <td>
                    <ul className="nav">
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#/">
                                <i className="fas fa-ellipsis-h" />
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>
                                {/* <Link to={`/cooperativas/${desc.cooperativa_id}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link> */}
                                {/* <Link disabled to='#/' data-toggle='modal' className='dropdown-item'><i className='fas fa-trash'></i> Excluir</Link> */}
                                <Link to={`/cooperativas/${desc.cooperativa_id}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
                            </div>
                        </li>

                    </ul>
                </td>

            </tr>
        ))
    }

    render() {
        const cooperativa = 'cooperativa.des_cooperativa';
        const empresa = 'empresa.nome';

        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <StylePage>
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>

                    ) : ('')}
                    {this.state.success ? (
                        <Alert type="success">{this.state.success}</Alert>
                    ) : ("")}
                    {this.state.error ? (
                        <Alert type="danger">{this.state.error}</Alert>
                    ) : ("")}
                    <div style={{ display: this.state.loading === true ? ('none') : ('block') }}>

                        <Table
                            view={
                                <div className="col-sm-12 col-md-5">
                                    <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                        Exibindo de {this.props.from === null ? 0 : this.props.from} à {this.props.to === null ? 0 : this.props.to} do total de {this.props.qtd_total}
                                    </div>
                                </div>
                            }
                            cabecalho={
                                <Cabecalho to="#/" action={this.props.get} clear={this.props.clearPesquisa} valuePesquisa={this.props.pesquisa} value={this.props.qtd_per_page} onChange={this.props.change_qtd_per_page} changePesquisa={this.props.changePesquisa} />
                            }
                            pagination={<Pagination>{this.props.pagination(this.props.current_page, this.props.get, this.props.last_page)}</Pagination>} title="Cooperativas" button={<Link to="/new_cooperativa" type="button" className="btn btn-secondary">Cadastro</Link>}>
                            <Thead>
                                <th>COOPERATIVA<Link to="/cooperativas" style={{ float: 'right' }} onClick={() => this.props.order(cooperativa)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>EMPRESA<Link to="/cooperativas" style={{ float: 'right' }} onClick={() => this.props.order(empresa)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th>Status</th>
                                <th>Ações</th>
                            </Thead>
                            <Tbody>
                                {this.renderGrid()}
                            </Tbody>
                            <Tfoot>
                                <th>COOPERATIVA</th>
                                <th>EMPRESA</th>
                                <th>Status</th>
                                <th>Ações</th>
                            </Tfoot>
                        </Table>
                    </div>
                </StylePage>


                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    cooperativas: state.cooperativa.cooperativas.data,
    current_page: state.cooperativa.cooperativas.current_page,
    last_page: state.cooperativa.cooperativas.last_page,
    from: state.cooperativa.cooperativas.from,
    to: state.cooperativa.cooperativas.to,
    qtd_total: state.cooperativa.cooperativas.total,
    qtd_per_page: state.cooperativa.qtd_per_page,
    pesquisa: state.cooperativa.pesquisa
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ get, pagination, order, change_qtd_per_page, changePesquisa, clearPesquisa, handleCheeseChange }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(grid_cooperativas)

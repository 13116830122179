import React, { Component } from 'react'
import fonts from "../../fonts/fonts.js";

export default class input extends Component {
    render() {
        return (
            <React.Fragment>
                <label style={{fontFamily: fonts.primary, fontSize: "1rem"}}>{this.props.label}</label>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text ">
                            <i 
                                className={`fas ${this.props.icon}`} 
                            />
                        </span>
                    </div>
                    <input 
                        type={this.props.type} 
                        name={this.props.name} 
                        className="form-control" 
                        placeholder={this.props.placeholder} 
                        onChange={this.props.onChange}
                        value={this.props.value}
                        disabled={this.props.disabled || false}
                        required={this.props.required || false}
                        style={{fontFamily: fonts.primary, fontSize: "1rem"}}
                        min={this.props.min}
                        maxLength={this.props.maxLength}
                        step={this.props.step}
                        // onKeyDown={(e) => e.preventDefault()}
                    />
                </div>
            </React.Fragment>
            
        )
    }
}

import React, { Component } from 'react'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Footer from '../../Footer/footer'
import Table from '../../Table/table'
import Thead from '../../Table/thead'
import Tbody from '../../Table/tbody'
import Tfoot from '../../Table/tfoot'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { get, order, change_qtd_per_page, changePesquisa, clearPesquisa, handleCheeseChange} from './Actions/actions'
import {pagination} from '../../Pagination/paginations'
import {Link} from 'react-router-dom'
import Pagination from '../../Table/pagination'
import Cabecalho from '../../Table/cabecalho'
import StylePage from '../../Style Page/style_page'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import ReactLoading from 'react-loading'
import { Content } from '../Users/styleLoading'
import Alert from '../../Alerts/alert'
import ModalExcluir from '../../Modal/modal'
import { delete_categoria } from '../../Services/categoria'

class grid_categoria extends Component {
    state = {
        loading:false,
        modal:false, 
        id:'', 
        success:'', 
        error:''

    }
    

    componentDidMount(){
        this.props.get(1)
    }

    onChangeModal = (id) => {
        this.setState({modal:true, id:id})
    }

    handleCloseExcluir = event => {
        this.setState({modal:false, id:''})
    }

    handleExcluir = (e) => {
        e.preventDefault();
        this.setState({loading:true,modal:false})
        delete_categoria(this.state.id)
        .then(resp => {this.setState({ success:resp.data.success, error:'',loading:false})})
        .then(resp => (setTimeout(()=>{
            this.setState({success:''})

        },2000)))
        .catch(e => {this.setState({loading:false,error:e.response.data.error, success:'', modal:false})})
        .then(resp => (this.props.get()))
        // .then(resp => {this.setState({success:resp.data.success, error:''})})

    }

   
    //Função que mapeia o objeto com as pilulas
    renderGrid(){
        const categorias = this.props.categorias || []
        return categorias.map(desc=>(
            <tr key={desc.id}>
                <td>{desc.nome}</td>
                <td style={{backgroundColor: `${desc.cor}`}}></td>
                <td className="text-center">
                <Toggle
                    
                    id={desc.id}
                    checked={desc.ativo === 1 ?(true):(false)}
                    onChange={this.props.handleCheeseChange} />
                    {/* <label htmlFor='cheese-status'>Adjacent label tag</label> */}

                </td> 
                <td>
                    <ul className="nav">
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#/">
                                <i className="fas fa-ellipsis-h" />             
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>                  
                            {/* <Link to={`/categoria/${desc.id}/view`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link> */}
                                {/* <Link disabled to='#/' data-toggle='modal' className='dropdown-item'><i className='fas fa-trash'></i> Excluir</Link> */}
                                <Link to={`/categoria/${desc.id}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
                                <Link to="#/" className='dropdown-item' onClick={()=>this.onChangeModal(desc.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>                                
                            </div>
                        </li>

                    </ul>		
				</td> 
                
            </tr>
        ))
    }
    
    render() {
        const nome = 'categoria.nome';
        
    
        
        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <StylePage>
                {this.state.loading === true ? (
                    <Content>
                        <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                    </Content>
                        
                    ):('')}
                    {this.state.success ? (
                        <Alert type="success">{this.state.success}</Alert>
                    ):("")}
                    {this.state.error ? (
                        <Alert type="danger">{this.state.error}</Alert>
                    ):("")}
                    <ModalExcluir
                        show={this.state.modal}
                        onHide={this.handleCloseExcluir}
                        title="Excluir Categoria"
                        content="Tem certeza que deseja excluir a categoria ?"
                        cancel={this.handleCloseExcluir}
                        del={this.handleExcluir}
                        nameAction="Excluir"
                        typeButton="danger"     
                        to="/users"                   
                    />
                    <div style={{display: this.state.loading === true ? ('none'):('block')}}>

                        <Table 
                            view={
                                <div className="col-sm-12 col-md-5">
                                    <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                        Exibindo de {this.props.from === null ? 0 : this.props.from} à {this.props.to === null ? 0 : this.props.to} do total de {this.props.qtd_total}
                                    </div>
                                </div>
                            } 
                            cabecalho={
                                <Cabecalho to="#/" action={this.props.get} clear={this.props.clearPesquisa} valuePesquisa={this.props.pesquisa} value={this.props.qtd_per_page} onChange={this.props.change_qtd_per_page} changePesquisa={this.props.changePesquisa} />
                            }
                            pagination={<Pagination>{this.props.pagination(this.props.current_page,this.props.get,this.props.last_page)}</Pagination>} title="Categorias" button={<Link to="/new_categoria" type="button" className="btn btn-secondary">Cadastro</Link>}>
                            <Thead>
                                <th className="text-center">Nome<Link to="/categorias" style={{float:'right'}} onClick={()=>this.props.order(nome)}><i className="text-right fas fa-sort-amount-down-alt"></i></Link></th>
                                <th className="text-center">Cor</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Ações</th>
                            </Thead>
                            <Tbody>
                                {this.renderGrid()}
                            </Tbody>
                            <Tfoot>
                                <th className="text-center">Nome</th>
                                <th className="text-center">Cor</th>
                                <th className="text-center">Status</th>
                                <th className="text-center">Ações</th>
                            </Tfoot>
                        </Table>        
                    </div>
                </StylePage>

                
                <Footer />
            </div>  
        )
    }
}

const mapStateToProps = state => ({
    categorias:state.categoria.categorias.data,
    current_page:state.categoria.categorias.current_page,
    last_page:state.categoria.categorias.last_page,
    from:state.categoria.categorias.from,
    to:state.categoria.categorias.to,
    qtd_total:state.categoria.categorias.total,
    qtd_per_page:state.categoria.qtd_per_page,
    pesquisa:state.categoria.pesquisa

   
  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({get,pagination,order,change_qtd_per_page, changePesquisa, clearPesquisa, handleCheeseChange}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(grid_categoria)

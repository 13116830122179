const SetorReducer = (state = {}, actions)=>{
    switch(actions.type){

        case 'GetSetor_Desc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}

        case 'GetSetor_Asc':
            return{...state,desc:actions.desc,asc:actions.asc,order:actions.order,th:actions.th}

        case 'qtd_per_pageSetor':
            return{...state, qtd_per_page:actions.payload}

        case 'pesquisaSetor':
            return{...state, pesquisa:actions.payload}

        case 'clearPesquisaSetor':
            return{...state, pesquisa:actions.payload}

        case 'get_Setor':
            return{...state, setores:actions.payload}
        default:
            return state;
    }
}
export default SetorReducer;
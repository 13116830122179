import React, { Component } from 'react'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Footer from '../../Footer/footer'
import Table from '../../Table/table'
import Thead from '../../Table/thead'
import Tbody from '../../Table/tbody'
import Tfoot from '../../Table/tfoot'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, order, change_qtd_per_page, changePesquisa, clearPesquisa } from '../Setores/Actions/actions'
import { pagination } from '../../Pagination/paginations'
import { Link } from 'react-router-dom'
import Pagination from '../../Table/pagination'
import Cabecalho from '../../Table/cabecalho'
import StylePage from '../../Style Page/style_page'
import "react-toggle/style.css"
import Toggle from 'react-toggle'
import ReactLoading from 'react-loading'
import { Content } from '../Users/styleLoading'
import Alert from '../../Alerts/alert'
import ModalExcluir from '../../Modal/modal'
import { delete_setor } from '../../Services/setor'

class grid_cooperativas extends Component {
    state = {
        loading: false,
        modal: false,
        id: '',
        success: '',
        error: ''

    }
    componentDidMount() {
        this.props.get(1)
    }

    onChangeModal = (id) => {
        this.setState({ modal: true, id: id })
    }

    handleCloseExcluir = event => {
        this.setState({ modal: false, id: '' })
    }

    handleExcluir = event => {
        event.preventDefault();

        this.setState({loading:true,modal:false})
        delete_setor(this.state.id)
        .then(resp => {
            this.setState({ 
                success: resp.data.success, 
                error:'',
                loading:false
            })
        })
        .then(resp => (setTimeout(()=>{
            this.setState({success:''})
        },2000)))
        .catch(e => {
            this.setState({loading:false,error:e.response.data.error, success:'', modal:false})
            setTimeout(() => {
                this.setState({error:'', success:'' })
            }, 6000)
        })
        .then(resp => (this.props.get()))
    }

    //Função que mapeia o objeto com as pilulas
    renderGrid() {
        const setores = this.props.setores || []
        return setores.map(desc => (
            <tr key={desc.id}>
                <td className='col-md-8' style={{ lineBreak: "anywhere"}}>{desc.des_setor}</td>
                <td>
                    <ul className="nav">
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#/">
                                <i className="fas fa-ellipsis-h" />
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>
                                <Link to={`/setores/${desc.id}/edit`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
                                <Link to="#/" className='dropdown-item' onClick={()=>this.onChangeModal(desc.id)} ><i className='fas fa-trash-alt'></i> Excluir</Link>
                            </div>
                        </li>

                    </ul>
                </td>

            </tr>
        ))
    }

    render() {
        const setor = 'setor.descricao';

        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <StylePage>
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>

                    ) : ('')}
                    {this.state.success ? (
                        <Alert type="success">{this.state.success}</Alert>
                    ) : ("")}
                    {this.state.error ? (
                        <Alert type="danger" hidden={true}>{this.state.error}</Alert>
                    ) : ("")}
                    <ModalExcluir
                        show={this.state.modal}
                        onHide={this.handleCloseExcluir}
                        title="Excluir Setor"
                        content="Tem certeza que deseja excluir o setor?"
                        cancel={this.handleCloseExcluir}
                        del={this.handleExcluir}
                        nameAction="Excluir"
                        typeButton="danger"
                        to="/setores"                   
                    />
                    <div style={{ display: this.state.loading === true ? ('none') : ('block') }}>
                    
                        <Table
                            view={
                                <div className="col-sm-12 col-md-5">
                                    <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                        Exibindo de {this.props.from > 0 ? this.props.from : 0} à {this.props.to > 0 ? this.props.to : 0} do total de {this.props.qtd_total}
                                    </div>
                                </div>
                            }
                            cabecalho={
                                <Cabecalho 
                                    to="#/" 
                                    action={this.props.get} 
                                    clear={this.props.clearPesquisa} 
                                    valuePesquisa={this.props.pesquisa} 
                                    value={this.props.qtd_per_page} 
                                    onChange={this.props.change_qtd_per_page} 
                                    changePesquisa={this.props.changePesquisa} 
                                />
                            }
                            pagination={
                                <Pagination>{
                                        this.props.pagination(
                                            this.props.current_page, 
                                            this.props.get,
                                            this.props.last_page
                                        )
                                    }
                                </Pagination>
                                } 
                                title="Setores" 
                                button={
                                    <Link 
                                        to="/new_setor" 
                                        type="button" 
                                        className="btn btn-secondary"
                                    >Cadastro
                                    </Link>
                                }>
                            <Thead>
                                <th>SETOR<Link 
                                    to="/setores" 
                                    style={{ float: 'right' }} 
                                    onClick={() => this.props.order(setor)}>
                                        <i className="right fas fa-sort-amount-down-alt"></i>
                                    </Link>
                                </th>
                                <th>Ações</th>
                            </Thead>
                            <Tbody>
                                {this.renderGrid()}
                            </Tbody>
                            <Tfoot>
                                <th>SETOR</th>
                                <th>Ações</th>
                            </Tfoot>
                        </Table>
                    </div>
                </StylePage>
                <Footer />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    setores: state.setor.setores.data,
    current_page: state.setor.setores.current_page,
    last_page: state.setor.setores.last_page,
    from: state.setor.setores.from,
    to: state.setor.setores.to,
    qtd_total: state.setor.setores.total,
    qtd_per_page: state.setor.qtd_per_page,
    pesquisa: state.setor.pesquisa
});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ get, pagination, order, change_qtd_per_page, changePesquisa, clearPesquisa }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(grid_cooperativas)

import React, { Component } from 'react'
import chroma from 'chroma-js';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Form from '../../Forms/form.jsx'
import Input from '../../Forms/input.jsx'
import Select from '../../Forms/select.jsx'
import Header from '../../Header/header.jsx'
import Sidebar from '../../Sidebar/sidebar.jsx'
import Style from '../../Style Page/style_page.jsx'
import Footer from '../../Footer/footer.jsx'
import { post_setor, put_setor, view_setor } from '../../Services/setor.js'
import Alert from '../../Alerts/alert.jsx'
import ReactLoading from 'react-loading'
import { Content } from '../Cooperativas/styleLoading.js'
import { Link } from 'react-router-dom';
import fonts from "../../../fonts/fonts.js";

export default class cooperativas extends Component {
    state = {
        descricao: '',
        error: '',
        alert: '',
        success: '',
        action: '',
        display: 'none',
        loading: false,
        empresas: [],
    }

    componentDidMount() {
        const action = this.props.match.params.action
        this.setState({ action: action })

        if (action === 'edit' || action === 'view') {
            view_setor(this.props.match.params.id)
                .then(resp => (
                    this.setState({
                        descricao: resp.data.nome_setor,
                    })
                ))
        }
    }

    handleForm = (e) => {
        e.preventDefault();
        this.setState({ loading: true });
        const data = {
            descricao: this.state.descricao,
        }

        if (!this.state.action) {
            post_setor(data)
                .then(resp => (
                    this.setState({ loading: false, success: resp.data.success, error: '' })
                ))
                .then(resp => (
                    setTimeout( () => {
                        window.location.reload()
                    }, 2000)
                ))
                .catch(e => (
                    this.setState({ loading: false, success: '', error: e.response.data.error })
                ))

        } else {
            const id = this.props.match.params.id
            put_setor(data, id)
                .then(resp => { this.setState({ loading: false, success: resp.data.success, error: '' }) })
                .then(resp => (
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000)))
                .catch(e => {
                    this.setState({ loading: false, success: '', error: e.response.data.error })
                    setTimeout(() => {
                        this.setState({ success: '', error: ''})
                    }, 3500)
                })
        }
    }

    changeName = e => {
        const nome = e.target.value
        this.setState({ descricao: nome })
    }

    render() {
        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <Style title={this.state.action === 'view' ? ('Visualização de Setor') : this.state.action === 'edit' ? ('Edição de Setor') : ('Cadastro de Setor')}>
                    {this.state.alert ? (
                        <Alert type="danger">
                            {this.state.alert}
                        </Alert>
                    ) : ('')}
                    {this.state.error ? (
                        <Alert type="danger" hidden={true}>
                            {this.state.error}
                        </Alert>
                    ) : ('')}
                    {this.state.success ? (
                        <Alert type="success">
                            {this.state.success}
                        </Alert>
                    ) : ('')}
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>

                    ) : ('')}
                    <div style={{ display: this.state.loading === true ? ('none') : ('block') }}>
                        <Form onSubmit={this.handleForm}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Nome Setor"
                                        placeholder="Digite um nome para o setor"
                                        type="text"
                                        required={true}
                                        icon="fas fa-briefcase"
                                        value={this.state.descricao}
                                        maxLength={100}
                                        onChange={this.changeName}
                                    />
                                </div>
                            </div>
                            <div className="text-right mt-5">
                                <button className="btn btn-primary" type="submit" style={{fontFamily: fonts.primary, fontSize: "1rem"}}>Salvar</button>
                                <Link to="/setores" type="button" className="btn btn-secondary" style={{ marginLeft: '10px' }}>Cancelar</Link>
                            </div>

                        </Form>
                    </div>
                </Style>
                <Footer />

            </div>
        )
    }
}



import React, { Component } from 'react'
import $ from 'jquery'
import Style from '../../Style Page/style_page'
import Form from '../../Forms/form'
import Input from '../../Forms/input'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import TextArea from '../../Forms/textArea'
import Select from '../../Forms/select'
import Footer from '../../Footer/footer'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { Content } from './styleLoading'
import ReactLoading from 'react-loading'
import Alert from '../../Alerts/alert'
import { nivel_pilula, categoria_pilula, post_pilula } from '../../Services/Pilula'
import { changeVideo, clear, changeNivel, changeCategoria, changeMsgNotificacao, changeTitulo, changeTituloNotificacao, changeTipo, changePontos, changePontosVisualizacao, changeTexto, changePergunta, changeRespostaA, changeRespostaB, changeRespostaC, changeRespostaD, changeRespostaCorreta, changeFeedBackCorreto, changeFeedBackErrado, post, changeImageIcone, changeImageFundo } from './Actions/actions'
import fonts from "../../../fonts/fonts.js";


class cadastro_pilulas extends Component {
    state = { nivelPilula: [], 
        categoriaPilula: [], 
        audio: [], 
        imageFundo: [], 
        success: '', 
        error: '', 
        
        loading: false,
        errorTitle: false,
        errorType: false,
        errorCategory: false,
        errorLevel: false,
        errorPoints: false,
        errorPointsView: false,
        errorURL: false,
    }


    formValidate = () => {
        return (
            this.titleValidate()      &&
            this.typeValidate()       &&
            this.categoryValidate()   &&
            this.levelValidate()      &&
            this.pointsValidate()     &&
            this.pointsViewValidate() &&
            (this.props.tipo == 'video' ? this.videoValidate() : true)
        );
            
    }

    setErrors = () => {
        if(!this.titleValidate()){
            this.setState({errorTitle : true});
        }else
            this.setState({errorTitle : false});

        if(!this.typeValidate())
            this.setState({errorType : true});
        else
            this.setState({errorType : false});

        if(!this.categoryValidate())
            this.setState({errorCategory : true});
        else
            this.setState({errorCategory : false});

        if(!this.levelValidate())
            this.setState({errorLevel : true});
        else
            this.setState({errorLevel : false});

        if(!this.pointsValidate())
            this.setState({errorPoints : true});
        else
            this.setState({errorPoints : false});

        if(!this.pointsViewValidate())
            this.setState({errorPointsView : true});
        else
            this.setState({errorPointsView : false});

        if(this.props.tipo == 'video'){
            if(!this.videoValidate())
                this.setState({ errorURL : true});
            else
                this.setState({ errorURL : false });
        }
    }

    titleValidate = () => {
        if(this.props.titulo == '')
            return false;
        return true;
    }

    typeValidate = () => {
        if(this.props.tipo == ''){
            return false;
        }
        return true;
    }

    categoryValidate = () => {
        if(this.props.categoria == ''){
            return false;
        }
        return true;
    }

    levelValidate = () => {
        if(this.props.nivel == '') {
            return false;
        }
        return true;
    }

    pointsValidate = () => {
        let regex = new RegExp('^[0-9]*$');

        if(regex.test(this.props.pontos)){
            return true;
        }
        return false;
    }

    pointsViewValidate = () => {
        let regex = new RegExp('^[1-9]+$');

        if(regex.test(this.props.pontosVisualizacao)){
            return true;
        }
        return false;
    }

    videoValidate = () => {
        if(this.props.video == '') {
            return false;
        }
        return true;
    }


    componentDidMount() {
        this.props.clear()
        const script = document.createElement("script");

        script.src = `js/textarea.js`
        script.async = true;
        document.body.appendChild(script);

        nivel_pilula()
            .then(resp => (
                this.setState({ nivelPilula: resp.data })
            ))

            .then(resp => (
                categoria_pilula(this.props.empresa_id)
                    .then(resp => (this.setState({ categoriaPilula: resp.data })))
            ))



    }

    onChangeAudio = event => {
        const file = event.target.files[0]
        this.setState({ audio: file })


    }

    onChangeFundo = event => {
        const file = event.target.files[0]
        this.setState({ imageFundo: file })


    }

    // fileUpload(image){
    //     console.log(image)
    //     debugger
    //     const data = new FormData();

    //     data.append('file',image)
    //     data.append('id',this.props.id)

    //     axios.post(`${URL}`,data,{
    //     headers: {
    //         "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
    //     },


    //     }).then(resp=>{this.componentDidMount()})
    // }


    //Mapeando o objeto com os níveis das pílulas
    nivelPilula() {
        const nivelPilula = this.state.nivelPilula || [];
        return nivelPilula.map(desc => (
            <option key={desc.id} value={desc.id}>{desc.descricao}</option>
        ))
    }

    //Mapeando o objeto com as categorais da pílulas
    categoriaPilula() {
        const categoriaPilula = this.state.categoriaPilula || [];
        return categoriaPilula.map(desc => (
            <option key={desc.id} value={desc.id}>{desc.nome}</option>
        ))

    }
    handleForm = (e) => {
        e.preventDefault();
        this.setErrors();

        if(this.formValidate()){
            this.setState({ loading: true })

            const texto = $('.textarea').val();
            const data = new FormData();

            data.append('tipo', this.props.tipo)
            data.append('categoria_id', this.props.categoria)
            data.append('empresa_id', this.props.empresa_id)
            data.append('titulo', this.props.titulo)
            data.append('push_titulo', this.props.tituloNotificacao)
            data.append('push_mensagem', this.props.msgNotificacao)
            data.append('audio', this.state.audio)
            data.append('push_big_picture', this.state.imageFundo)
            data.append('nivel', this.props.nivel)
            data.append('pontos', this.props.pontos || '')
            data.append('texto', texto)
            data.append('pergunta', this.props.pergunta || '')
            data.append('opcao_a', this.props.respostaA || '')
            data.append('opcao_b', this.props.respostaB || '')
            data.append('opcao_c', this.props.respostaC || '')
            data.append('opcao_d', this.props.respostaD || '')
            data.append('opcao_correta', this.props.respostaCorreta || '')
            data.append('feedback_correto', this.props.feedBackCorreto || '')
            data.append('feedback_errado', this.props.feedBackErrado || '')
            data.append('usuario_id', this.props.usuario_id)
            data.append('video', this.props.video || '')
            data.append('pontosVisualizacao', this.props.pontosVisualizacao || 0)

            post_pilula(data, {
                headers: {
                    "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                },
            })

            .then(resp => {
                
                this.setState({ loading: false, success: resp.data.success, error: '' })
            })
            .then(resp => {
                setTimeout(() => {
                    window.location.reload()
                }, 2500)
            })
            .catch(e => {
                this.setState({ loading: false, success: '', error: e.response.data.error })
                setTimeout(() => {
                    this.setState({ loading: false, success: '', error: '' })
                }, 4500)
            })
        }else {
            this.setErrors();
        }
    }

    render() {
        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <Style title="Cadastro de Pilulas" subtitle="Cadastrar Pílula">
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>

                    ) : ('')}
                    {this.state.error ? (
                        <div>
                            <Alert type='danger'>
                                {this.state.error}
                            </Alert>
                        </div>
                    ) : ('')}
                    {this.state.success ? (
                        <div>
                            <Alert type='success'>
                                {this.state.success}
                            </Alert>
                        </div>
                    ) : ('')}
                    <div style={{ display: this.state.loading === true ? ('none') : ('block') }}>
                        <Form onSubmit={this.handleForm}>
                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        label="Título"
                                        onChange={this.props.changeTitulo}
                                        icon="fa-assistive-listening-systems"
                                        name="titulo"
                                        type="text"
                                        placeholder="Digite o título da pílula"
                                    />
                                    <p style={{color: 'red'}}>{this.state.errorTitle ? '*O campo Titulo é obrigatótio' : ''}</p>
                                </div>
                                {/* <div className="col-md-6">
                                    <Input label="Título da Notificação" onChange={this.props.changeTituloNotificacao} icon="fa-assistive-listening-systems" name="titulo_notificacao" type="text" placeholder="Digite o título de notificação da pílula"/>
                                </div> */}
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12">
                                <Input label="Mensagem da Notificação" onChange={this.props.changeMsgNotificacao} icon="fa-assistive-listening-systems" name="mensagem_notificacao" type="text" placeholder="Digite a mensagem da notificação"/>

                                </div>

                            </div> */}
                            <div className="row">
                                <div className="col-md-3">
                                    <Select
                                        onChange={this.props.changeTipo}
                                        label="Tipo"
                                    >
                                        <option>Selecione um tipo</option>
                                        <option value="texto">Texto</option>
                                        <option value="audio">Áudio</option>
                                        <option value="video">Vídeo</option>
                                    </Select>
                                    <p style={{color: 'red'}}>{this.state.errorType ? '*O campo Tipo é obrigatótio' : ''}</p>
                                </div>
                                <div className="col-md-3">
                                    <Select
                                        onChange={this.props.changeCategoria}
                                        label="Categoria"
                                    >
                                        <option>Selecione a categoria</option>
                                        {this.categoriaPilula()}
                                    </Select>
                                    <p style={{color: 'red'}}>{this.state.errorCategory ? '*O campo Categoria é obrigatótio' : ''}</p>
                                </div>
                                <div className="col-md-3">
                                    <Select
                                        onChange={this.props.changeNivel}
                                        label="Nível"
                                    >
                                        <option>Selecione o nível</option>
                                        {this.nivelPilula()}
                                    </Select>
                                    <p style={{color: 'red'}}>{this.state.errorLevel ? '*O campo Nível é obrigatótio' : ''}</p>
                                </div>
                                <div className="col-md-3">
                                    <Input
                                        onChange={this.props.changePontos}
                                        label="Pontos"
                                        icon="fa-balance-scale"
                                        name="pontos"
                                        type="number"
                                        placeholder="Digite a pontuação"
                                    />
                                    <p style={{color: 'red'}}>{this.state.errorPoints ? '*O campo Pontos deve ser um número positivo' : ''}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <label>Imagem de Fundo :</label>
                                    <div className="custom-file">
                                        <input onChange={this.onChangeFundo} type="file" className="custom-file-input" id="imagem" />
                                        <label className="custom-file-label" htmlFor="customFile">Escolha um arquivo</label>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        onChange={this.props.changePontosVisualizacao}
                                        label="Pontos de visualização"
                                        icon="fa-balance-scale"
                                        name="pontosVisualizacao"
                                        type="number"
                                        step={1}
                                        min={0}
                                        maxLength={'9'}
                                        placeholder="Digite a pontuação por visualização"
                                    />
                                    <p style={{color: 'red'}}>{this.state.errorPointsView ? '*O campo Pontos de Visualização deve ser um inteiro maior que 0' : ''}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <TextArea
                                        label="Texto"
                                        placeholder="Digite o texto da pílula"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                {/* <div className="col-md-6">
                                    <label>Ícone de Notificação:</label>
                                    <div className="custom-file">
                                        <input onChange={this.onChangeIcone} type="file" className="custom-file-input" id="icone"/>
                                        <label className="custom-file-label" htmlFor="customFile">Escolha um arquivo</label>
                                    </div>
                                </div> */}
                                {this.props.tipo == 'audio' ? (
                                    <div className="col-md-6">
                                        <label>Áudio :</label>
                                        <div className="custom-file">
                                            <input onChange={this.onChangeAudio} type="file" className="custom-file-input" id="imagem" />
                                            <label className="custom-file-label" htmlFor="customFile">Escolha um áudio</label>
                                        </div>
                                    </div>

                                ) : ('')}
                                {this.props.tipo == 'video' ? (
                                    <div className="col-md-6">
                                        <Input label="Vídeo" onChange={this.props.changeVideo} icon="fa-assistive-listening-systems" name="video" type="text" placeholder="Cole aqui o link do vídeo" />
                                        <p style={{color: 'red'}}>{this.state.errorURL ? '*O campo Vídeo é obrigatório' : ''}</p>

                                        <p><strong>Exemplo de links permitidos:</strong></p>
                                        <p>Vimeo: (https://vimeo.com/647475206) ou (https://vimeo.com/675435172/cd97766f48)</p><span></span>
                                        <p>YouTube: (https://www.youtube.com/watch?v=GhAIxKqEqtM)</p>

                                    </div>

                                ) : ('')}


                            </div>
                            <br></br>
                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        type="text"
                                        icon="fa-question"
                                        onChange={this.props.changePergunta}
                                        label="Pergunta"
                                        placeholder="Digite a pergunta ..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        type="text"
                                        icon="fa-dice-d6"
                                        onChange={this.props.changeRespostaA}
                                        label="Resposta A"
                                        placeholder="Digite a resposta A ..."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        type="text"
                                        icon="fa-dice-d6"
                                        onChange={this.props.changeRespostaB}
                                        label="Resposta B"
                                        placeholder="Digite a resposta B ..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        type="text"
                                        icon="fa-dice-d6"
                                        onChange={this.props.changeRespostaC}
                                        label="Resposta C"
                                        placeholder="Digite a resposta C ..."
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Input
                                        type="text"
                                        icon="fa-dice-d6"
                                        onChange={this.props.changeRespostaD}
                                        label="Resposta D"
                                        placeholder="Digite a resposta D ..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <Select
                                        onChange={this.props.changeRespostaCorreta}
                                        label="Resposta Correta"
                                    >
                                        <option>Selecione a resposta correta</option>
                                        <option>A</option>
                                        <option>B</option>
                                        <option>C</option>
                                        <option>D</option>
                                    </Select>
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        onChange={this.props.changeFeedBackCorreto}
                                        label="Feedback Correto"
                                        type="text"
                                        icon="fa-smile"
                                    />
                                </div>
                                <div className="col-md-4" style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>
                                    <Input
                                        onChange={this.props.changeFeedBackErrado}
                                        label="Feedback Errado"
                                        type="text"
                                        icon="fa-frown"
                                        style={{ fontFamily: fonts.primary, fontSize: "1rem" }}
                                    />
                                </div>
                            </div>
                            <br></br>
                            <div className="text-right">
                                <button type="submit" className="btn btn-primary" style={{ fontFamily: fonts.primary, fontSize: "1rem" }}>Salvar</button>
                                <Link to="/pilulas" type="button" className="btn btn-secondary" style={{ marginLeft: '10px' }}>Cancelar</Link>
                            </div>
                        </Form>
                    </div>
                </Style>

                <Footer />
            </div>
        )
    }
}
const mapStateToProps = state => ({

    titulo: state.pilula.titulo,
    tituloNotificacao: state.pilula.tituloNotificacao,
    tipo: state.pilula.tipo,
    pontos: state.pilula.pontos,
    pontosVisualizacao: state.pilula.pontosVisualizacao,
    pergunta: state.pilula.pergunta,
    respostaA: state.pilula.respostaA,
    respostaB: state.pilula.respostaB,
    respostaC: state.pilula.respostaC,
    respostaD: state.pilula.respostaD,
    respostaCorreta: state.pilula.respostaCorreta,
    feedBackCorreto: state.pilula.feedBackCorreto,
    feedBackErrado: state.pilula.feedBackErrado,
    imageIcone: state.pilula.imageIcone,
    imageFundo: state.pilula.imageFundo,
    nivelPilula: state.pilula.nivelPilula,
    categoriaPilula: state.pilula.categoriaPilula,
    empresa_id: state.auth.user.empresa_id,
    msgNotificacao: state.pilula.msgNotificacao,
    categoria: state.pilula.categoria,
    nivel: state.pilula.nivel,
    usuario_id: state.auth.user.id_usuario,
    video: state.pilula.video

});
const mapDispatchToProps = dispatch =>
    bindActionCreators({ changeVideo, clear, changeNivel, changeCategoria, changeMsgNotificacao, changeTitulo, changeTituloNotificacao, changeTipo, changePontos, changePontosVisualizacao, changeTexto, changePergunta, changeRespostaA, changeRespostaB, changeRespostaC, changeRespostaD, changeRespostaCorreta, changeFeedBackCorreto, changeFeedBackErrado, post, changeImageIcone, changeImageFundo }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(cadastro_pilulas)

import { combineReducers } from "redux";
import AuthReducer from './AuthReducer';
import PilulaReducer from './PilulaReducer'
import AgendaPilulaReducer from './AgendaPilulaReducer'
import RelatorioReducer from "./RelatorioReducer";
import UserReducer from "./UserReducer";
import CooperativaReducer from "./CooperativaReducer";
import CategoriaReducer from "./CategoriaReducer";
import SetorReducer from "./SetorReducer";

const AllReducers = combineReducers({
    auth: AuthReducer, 
    pilula:PilulaReducer, 
    agenda_pilula:AgendaPilulaReducer,
    relatorio:RelatorioReducer,
    user:UserReducer,
    cooperativa:CooperativaReducer,
    categoria:CategoriaReducer,
    setor:SetorReducer
})

export default AllReducers;
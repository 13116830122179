import $ from 'jquery'
import { get_pilula, post_pilula, categoria_pilula } from '../../../Services/Pilula'




//Função que pega o titulo da pilula digitado
export const changeTitulo = event => {
    return[{
        type:'changeTituloPilula',
        payload:event.target.value
    }]
}

//Função que pega o titulo da notificação digitado
export const changeTituloNotificacao = event =>{
    return[{
        type:'changeTituloNotificacao',
        payload:event.target.value
    }]
}

//Função que pega a mensagem da notificação digitado
export const changeMsgNotificacao = event =>{
    return[{
        type:'changeMsgNotificacao',
        payload:event.target.value
    }]
}

//Função que pega o tipo selecionado
export const changeTipo = event =>{
    return[{
        type:'changeTipo',
        payload:event.target.value == 'Selecione um tipo' ? '' : event.target.value
    }]
}

//Função que pega o valor de pontos digitado no input
export const changePontos = event =>{
    return[{
        type:'changePontos',
        payload:event.target.value
    }]
}

//Função que pega o valor de pontos de visualização digitado no input
export const changePontosVisualizacao = event =>{
    return[{
        type:'changePontosVisualizacao',
        payload: event.target.value
    }]
}

//Função que pega o texto digitado
export const changeTexto = event =>{
    return[{
        type:'changeTexto',
        payload:event.target.value
    }]
}

//Função que pega a categoria 
export const changeCategoria = event =>{
    return[{
        type:'changeCategoria',
        payload:event.target.value == 'Selecione a categoria' ? '' : event.target.value
    }]
}

//Função que pega o nível 
export const changeNivel = event =>{
    return[{
        type:'changeNivel',
        payload:event.target.value == 'Selecione o nível' ? '' : event.target.value
    }]
}

//Função que pega a pergunta digitada no input
export const changePergunta = event =>{
    return[{
        type:'changePergunta',
        payload:event.target.value
    }]
}

//Função que pega a resposta A
export const changeRespostaA = event =>{
    return[{
        type:'changeRespostaA',
        payload:event.target.value
    }]
}

//Função que pega a resposta B
export const changeRespostaB = event =>{
    return[{
        type:'changeRespostaB',
        payload:event.target.value
    }]
}

//Função que pega a resposta C
export const changeRespostaC = event =>{
    return[{
        type:'changeRespostaC',
        payload:event.target.value
    }]
}

//Função que pega a resposta D
export const changeRespostaD = event =>{
    return[{
        type:'changeRespostaD',
        payload:event.target.value
    }]
}

//Função que pega a resposta correta
export const changeRespostaCorreta = event =>{
    return[{
        type:'changeRespostaCorreta',
        payload:event.target.value
    }]
}

//Função que pega o feedback correto
export const changeFeedBackCorreto = event =>{
    return[{
        type:'changeFeedBackCorreto',
        payload:event.target.value
    }]
}

//Função que pega o feedback errado
export const changeFeedBackErrado = event =>{
    return[{
        type:'changeFeedBackErrado',
        payload:event.target.value
    }]
}

//Função que pega a imagem do ícone de notificação 
export const changeImageIcone = event =>{
    return[{
        type:'changeImageIcone',
        payload:event.target.value
    }]
}
//Função que pega a imagem de fundo
export const changeImageFundo = event =>{
    return[{
        type:'changeImageFundo',
        payload:event.target.value
    }]
}

export const changeData = event => {
    return[{
        type:'changeDataAgenda',
        payload:event.target.value
    }]
}

export const changePeso = event => {
    return [{
        type:'changePeso',
        payload: event.target.value
    }]
}

export const changeCampanha = event => {
    return[{
        type:'changeCampanha',
        payload:event.target.value
    }]
}

export const changeSetor = event => {
    return[{
        type:'changeSetor',
        payload:event.target.value
    }]
}

//Função que salva os dados no banco
export const post = (titulo,tituloNotificacao,tipo,pontos,pontosVisualizacao,pergunta,respostaA,respostaB,respostaC,respostaD,respostaCorreta,feedBackCorreto,feedBackErrado,audio,imageFundo,msgNotificacao,categoriaPilula,empresa_id,nivelPilula,usuario_id,video)=>{
    return[{type:'getTexto', payload:$('.textarea').val()},(dispatch,getState) => {
        const texto = getState().pilula.texto 
        
        
        const data = new FormData();
       
        data.append('tipo',tipo)
        data.append('categoria_id',categoriaPilula)
        data.append('empresa_id',empresa_id)
        data.append('titulo',titulo)
        data.append('push_titulo',tituloNotificacao)
        data.append('push_mensagem',msgNotificacao)
        data.append('audio',audio)
        data.append('push_big_picture',imageFundo)
        data.append('nivel',nivelPilula)
        data.append('pontos',pontos)
        data.append('pontos',pontosVisualizacao)
        data.append('texto',texto)
        data.append('pergunta',pergunta)
        data.append('opcao_a',respostaA)
        data.append('opcao_b',respostaB)
        data.append('opcao_c',respostaC)
        data.append('opcao_d',respostaD)
        data.append('opcao_correta',respostaCorreta)
        data.append('feedback_correto',feedBackCorreto)
        data.append('feedback_errado',feedBackErrado)
        data.append('usuario_id',usuario_id) 
        data.append('video',video) 
        
        post_pilula(data,{headers: {
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },})
       
        // axios.post(`${URL_PILULA}`,data,{
        //     headers: {
        //         "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        //     },
     

        // })
   

    }]
}

//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        const order = getState().pilula.order
        const th = getState().pilula.th
        const qtd_per_page = getState().pilula.qtd_per_page
        const pesquisa = getState().pilula.pesquisa || ''
        const empresa_id = getState().auth.user.empresa_id
        
       
      
        get_pilula(page,qtd_per_page,order,th,pesquisa,empresa_id)
        .then(resp=>dispatch({type:'get_Pilula', payload:resp.data}))

    }]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().pilula.asc
      
        if(asc === true){
            dispatch({type:'GetPilula_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetPilula_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]
    
    
}

export const clear = () => {
    return{
        type:'Clear',
        pilula_id:'',
        categoria:'',
        titulo:'',   
        tituloNotificacao:'',
        msgNotificacao:'',
        push_large_icon:'',

        push_big_picture:'',
        nivel:'',
        pontos:'',
        pontosVisualizacao:'',
        texto:'',
        video:'',
        audio:'',
        pergunta:'',
        respostaA:'',     
        respostaB:'',     
        respostaC:'',
        respostaD:'',
        respostaCorreta:'',
        feedBackCorreto:'',
        feedBackErrado:'',
        tipo:''

    }
}

export const editPilula = (data) => {
    return  [{

        type:'EditView',
        pilula_id:data.id,
        categoria:data.categoria_id,
        titulo:data.titulo,
        tituloNotificacao:data.push_titulo,
        msgNotificacao:data.push_mensagem,
        push_large_icon:data.push_large_icon,
        push_big_picture:data.push_big_picture,
        nivel:data.nivel,
        pontos:data.pontos,
        pontosVisualizacao: data.pontosVisualizacao,
        texto:data.texto,
        video:data.video,
        audio:data.audio,
        pergunta:data.pergunta,
        respostaA:data.opcao_a,
        respostaB:data.opcao_b,
        respostaC:data.opcao_c,
        respostaD:data.opcao_d,
        respostaCorreta:data.opcao_correta,
        feedBackCorreto:data.feedback_correto,
        feedBackErrado:data.feedback_errado,
        tipo:data.tipo            
    }]     
}

export const getCategoria = (empresa_id) => {
    return (dispatch,getState) => {
        categoria_pilula(empresa_id).then(resp => dispatch({type:"Get_Categoria", payload:resp.data}))

    }
}

export const changeVideo = event => {
    return{
        type:'changeVideo',
        payload:event.target.value
    }
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_page',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisa',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisa',
        payload:''
    },get(1)]
}
    
import { get_setor } from '../../../Services/setor'

//Função que traz os dados do banco de dados
export const get = (page) =>{
    return[(dispatch,getState)=>{
        
        const order = getState().setor.order
        const th = 'setor.des_setor'
        const qtd_per_page = getState().setor.qtd_per_page
        const pesquisa = getState().setor.pesquisa || ''

        get_setor(page,qtd_per_page,order,th,pesquisa)
            .then( resp => dispatch( {type:'get_Setor', payload:resp.data} ))

    }]
}

export const change_qtd_per_page = event => {
    return[{
        type:'qtd_per_pageSetor',
        payload:event.target.value
    },get(1)]
}

export const changePesquisa = event => {
    return[{
        type:'pesquisaSetor',
        payload:event.target.value
    }]
}

export const clearPesquisa = event => {
    return[{
        type:'clearPesquisaSetor',
        payload:''
    },get(1)]
}

//Função que ordena as colunas
export const order = (coluna) => {
    return [(dispatch,getState)=>{
        const asc = getState().setor.asc
      
        if(asc === true){
            dispatch({type:'GetSetor_Desc', desc:true, asc:false,order:'desc',th:coluna})
            
        }if(asc === false){
            dispatch({type:'GetSetor_Asc', asc:true, desc:false,order:'asc',th:coluna})
            
        }
     
    },get(1)]
    
}

import './Dependencies/dependencies'
import './Dependencies/jquery-loader'
import React from 'react';
import {
  BrowserRouter ,Route, Switch 
} from "react-router-dom";

import Login from './Components/Pages/login'
import CadastroPilula from './Components/Pages/Pílulas/cadastro_pilulas'
import Pilula from './Components/Pages/Pílulas/grid_pilula'
import Index from './Components/Pages/index'
import AuthRouter from './Components/AuthRouter'
import EditPilula from './Components/Pages/Pílulas/edit_pilula'
import AgendaPilula from './Components/Pages/Pílulas/agenda_pilula'
import ViewPilula from './Components/Pages/Pílulas/view_pilula'
import GridAgendamento from './Components/Pages/Agenda_Pilulas/grid_agenda_pilula'
import Users from './Components/Pages/Users/register'
import GridRelatorio from './Components/Pages/Relatórios/relatorio'
import GridUser from './Components/Pages/Users/grid_users'
import GridCooperativa from './Components/Pages/Cooperativas/grid_cooperativas'
import Categorias from './Components/Pages/Categorais/categorias'
import Cooperativas from './Components/Pages/Cooperativas/cooperativas'
import GridCategoria from './Components/Pages/Categorais/grid_categoria'
import GridSetores from './Components/Pages/Setores/grid_setores'
import Setores from './Components/Pages/Setores/setores'

const Routes = () =>(
    <React.Fragment>
        <BrowserRouter>
            <Switch> 
                <Route exact path='/' component={Login}/>
                <Route path="/login" component={Login} />
                <AuthRouter path="/edit_pilula/:id" component={EditPilula} />
                <AuthRouter path="/view_pilula/:id" component={ViewPilula} />
                <AuthRouter path="/agenda_pilula/:id" component={AgendaPilula} />
                <AuthRouter path="/index" component={Index} />
                <AuthRouter path="/cadastro_pilula" component={CadastroPilula} />
                <AuthRouter path="/pilulas" component={Pilula} />
                <AuthRouter path="/agendamentos" component={GridAgendamento} />
                <AuthRouter path="/users" exact component={GridUser} />
                <AuthRouter path="/users/:id/:action" exact component={Users} />
                <AuthRouter path="/new_user" exact component={Users} />
                <AuthRouter path="/relatorios" component={GridRelatorio} />
                <AuthRouter path="/new_categoria" exact component={Categorias} />
                <AuthRouter path="/categorias" exact component={GridCategoria} />
                <AuthRouter path="/categoria/:id/:action" component={Categorias} />
                <AuthRouter path="/cooperativas" exact component={GridCooperativa} />
                <AuthRouter path="/cooperativas/:id/:action" component={Cooperativas} />
                <AuthRouter path="/new_cooperativa" exact component={Cooperativas} />
                <AuthRouter path="/setores" exact component={GridSetores} />
                <AuthRouter path="/setores/:id/:action" component={Setores} />
                <AuthRouter path="/new_setor" exact component={Setores} />
            </Switch>      
        </BrowserRouter>  
    </React.Fragment>
    
    
);
export default Routes;

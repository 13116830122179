import { app } from './Api'

const register = async (data) => {
    return app.post(`register`,data)
}

const get_setor = async (page,qtd,order,th,pesquisa) => {
    return app.get(`setor?page=${page}&qtd=${qtd}&order=${order}&th=${th}&pesquisa=${pesquisa}&lista=true`)
}

const view_setor = async (id) => {
    return app.get(`setor/${id}`)
}

const put_setor = async (data,id) => {
    return app.patch(`setor/${id}`,data)
}

const post_setor = async (data) => {
    return app.post('setor',data)
}

const delete_setor = async (id) => {
    return app.delete(`setor/${id}`)
}

export { 
    register, 
    get_setor, 
    view_setor, 
    post_setor, 
    put_setor,
    delete_setor
}
import React, { Component } from 'react'
import Header from '../../Header/header'
import Sidebar from '../../Sidebar/sidebar'
import Footer from '../../Footer/footer'
import Table from '../../Table/table'
import Thead from '../../Table/thead'
import Tbody from '../../Table/tbody'
import Tfoot from '../../Table/tfoot'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import { get, order, editPilula, change_qtd_per_page, changePesquisa, clearPesquisa} from './Actions/actions'
import {pagination} from '../../Pagination/paginations'
import {Link} from 'react-router-dom'
import Pagination from '../../Table/pagination'
import Cabecalho from '../../Table/cabecalho'
import StylePage from '../../Style Page/style_page'
import ModalExcluir from '../../Modal/modal'
import { compartilha_pilula, get_empresa } from '../../Services/Pilula'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import ReactLoading from 'react-loading'
import { Content } from '../Users/styleLoading'
import Alert from '../../Alerts/alert'


class grid_pilula extends Component {
    state={
        id:'', 
        empresa:[], 
        empresas:[], 
        success:'', 
        error:'',
        loading:false,
        modal:false, 
    }
    
    componentDidMount(){
        this.props.get(1)
        get_empresa()
        .then(resp => {
            this.setState({          
                empresas:resp.data.map(desc => ({
                    value: desc.id,
                    label: desc.nome
                }))          
            })
        })
        
    }

   
    //Função que mapeia o objeto com as pilulas
    renderGrid(){
        const pilulas = this.props.pilulas || []
        return pilulas.map(desc=>(
            <tr key={desc.id}>
                <td>{desc.dta_criacao}</td>
                <td className='col-md-4' style={{ lineBreak: "anywhere"}} >{desc.titulo}</td>
                <td className='col-md-2'>{desc.Nome_Categoria}</td>
                <td>{desc.tipo}</td>
                <td className='col-md-2'>{desc.Nome_Usuario}</td>
                <td>
                    <ul className="nav">
                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="#/">
                                <i className="fas fa-ellipsis-h" />             
                            </a>
                            <div className='dropdown-menu dropdown-menu-right'>                  
                                <Link to={`/view_pilula/${desc.id}`} className='dropdown-item'><i className='fas fa-eye'></i> Detalhes</Link>
                                {/* <Link disabled to='#/' data-toggle='modal' className='dropdown-item'><i className='fas fa-trash'></i> Excluir</Link> */}
                                <Link to={`/edit_pilula/${desc.id}`} className='dropdown-item' ><i className='fas fa-edit'></i> Editar</Link>
                                <Link to={`/agenda_pilula/${desc.id}`} className='dropdown-item' ><i className='fas fa-calendar-alt'></i> Agendar</Link> 
                                <Link to="#/" className='dropdown-item' onClick={()=>this.onChangeModal(desc.id)} ><i className='fas fa-share-alt'></i> Compartilhar</Link>                                
                            </div>
                        </li>

                    </ul>		
				</td>
                
            </tr>
        ))
    }

    onChangeModal = (id) => {
        this.setState({modal:true, id:id})
    }

    handleCloseCompartilha = event => {
        this.setState({modal:false, id:''})
    }

    handleCompartilha = (e) => {
        e.preventDefault();
        this.setState({loading:true,modal:false})
        let empresas = [];
        this.state.empresa.map(desc => empresas.push(parseInt(desc.value)))
        const data = {
            empresas: empresas
        }
        compartilha_pilula(this.state.id,data)
        .then(resp => {this.setState({ success:resp.data.success, error:'',loading:false, empresa:[]})})
        .then(resp => (setTimeout(()=>{
            this.setState({success:''})

        },2000)))
        .catch(e => {this.setState({loading:false,error:e.response.data.error, success:'', modal:false, empresa:[]})})
        .then(resp => (this.props.get()))
        // .then(resp => {this.setState({success:resp.data.success, error:''})})


        
    }

    handleChangeEmpresa = (newValue: any, actionMeta: any) => {
        this.setState({empresa: newValue}) 
    };

    render() {
        const data = 'pilula.dta_criacao';
        const titulo = 'pilula.titulo';
        const categoria = 'categoria.nome';
        const tipo = 'pilula.tipo'
        const usuario = 'usuario.nome'
        const animatedComponents = makeAnimated();
        
        return (
            <div className="wrapper">
                <Header />
                <Sidebar />
                <StylePage>
                    {this.state.loading === true ? (
                        <Content>
                            <ReactLoading type="spinningBubbles" color="blue" height={200} width={100} />
                        </Content>
                            
                        ):('')}
                        {this.state.success ? (
                            <Alert type="success">{this.state.success}</Alert>
                        ):("")}
                        {this.state.error ? (
                            <Alert type="danger">{this.state.error}</Alert>
                        ):("")}
                    <ModalExcluir
                        show={this.state.modal}
                        onHide={this.handleCloseCompartilha}
                        title="Compartilhar Pílula"
                        nameAction="Compartilhar"
                        typeButton="primary"
                        content={
                            <React.Fragment>

                                <label>Empresas</label>
                                <Select
                                    value={this.state.empresa}
                                    isMulti
                                    name="perfis"
                                    options={this.state.empresas}
                                    components={animatedComponents}
                                    closeMenuOnSelect={false}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.handleChangeEmpresa}
                                    
    
                                />
                            </React.Fragment>
                        }
                        cancel={this.handleCloseCompartilha}
                        del={this.handleCompartilha}     
                        to="/pilulas"                   
                    />


                    <Table 
                        view={
                            <div className="col-sm-12 col-md-5">
                                <div className="dataTables_info" id="example1_info" role="status" aria-live="polite">
                                    Exibindo de {this.props.from === null ? 0 : this.props.from } à {this.props.to === null ? 0 : this.props.to} do total de {this.props.qtd_total}
                                </div>
                            </div>
                        } 
                        cabecalho={
                            <Cabecalho to="#/" action={this.props.get} clear={this.props.clearPesquisa} valuePesquisa={this.props.pesquisa} value={this.props.qtd_per_page} onChange={this.props.change_qtd_per_page} changePesquisa={this.props.changePesquisa} />
                        }
                        pagination={<Pagination>{this.props.pagination(this.props.current_page,this.props.get,this.props.last_page)}</Pagination>} title="Pilulas" button={<Link to="/cadastro_pilula" type="button" className="btn btn-secondary">Cadastro</Link>}>
                        <Thead>
                            <th>Data da Criação<Link to="/pilulas" style={{float:'right'}} onClick={()=>this.props.order(data)}><i className="text-right fas fa-sort-amount-down-alt"></i></Link></th>
                            <th>Título<Link to="/pilulas" style={{float:'right'}} onClick={()=>this.props.order(titulo)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                            <th>Categoria<Link to="/pilulas" style={{float:'right'}} onClick={()=>this.props.order(categoria)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                            <th className='col-md-1' >Tipo<Link to="/pilulas" style={{float:'right'}} onClick={()=>this.props.order(tipo)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>
                            <th>Usuário<Link to="/pilulas" style={{float:'right'}} onClick={()=>this.props.order(usuario)}><i className="right fas fa-sort-amount-down-alt"></i></Link></th>  
                            <th>Ações</th>
                        </Thead>
                        <Tbody>
                            {this.renderGrid()}
                        </Tbody>
                        <Tfoot>
                            <th>Data da Criação</th>
                            <th>Título</th>
                            <th>Categoria</th>
                            <th>Tipo</th>
                            <th>Usuário</th>
                            <th>Ações</th>
                        </Tfoot>
                    </Table>        
                </StylePage>

                
                <Footer />
            </div>  
        )
    }
}

const mapStateToProps = state => ({
    pilulas:state.pilula.pilulas.data,
    current_page:state.pilula.pilulas.current_page,
    last_page:state.pilula.pilulas.last_page,
    from:state.pilula.pilulas.from,
    to:state.pilula.pilulas.to,
    qtd_total:state.pilula.pilulas.total,
    qtd_per_page:state.pilula.qtd_per_page,
    pesquisa:state.pilula.pesquisa

   
  
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({get,pagination,order,editPilula,change_qtd_per_page, changePesquisa, clearPesquisa}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(grid_pilula)
